import React from 'react';
import { Router } from '@reach/router';

import { App, Helmet } from 'modules/app';
import { MainNavigation } from 'modules/navigation';

import { StemiLabPage, AdventurePage } from 'modules/lab';

export default class LabPage extends React.Component {
  render() {
    return (
      <App>
        <Helmet route={StemiLabPage.route} title={StemiLabPage.title} />
        <MainNavigation />
        <Router>
          <StemiLabPage path="/adventure" />
          <AdventurePage path="/adventure/:adventureId" />
        </Router>
      </App>
    );
  }
}
